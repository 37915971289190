@media only screen and (max-width: 768px){
    .image{
        width: 100%;
        height: fit-content;
    }
    .imgtext{
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 12px;
        
       
    }
    
    
}


.image{
    width: 180px;
    height: 120px;
    float: left;
    margin-right: 25px;
}

.imgtext{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 15px;
    margin-left: 20px;
    text-align: justify;
}

.icons{
    width:40px;
    height:40px;
    margin-left: 15px;
}

.icons2{
    width: 80px !important;;
    height: 80px !important;;
    margin-left: 15px;
}

.imgbut{
    float: right;
    margin-left: 10px;
}

.resatnImage{
    font-size: 25px;
}

.guestformat{
    width: 50% !important;
    float: left;
}

.roomdetformat{
    width: 40% !important;
    Float: left;
    
}
.closing{
    margin-left: 100%;
}

.halldiv{
    background-color: black;
    color: silver;
    font-family: Georgia, 'Times New Roman', Times, serif;
    
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
    width: 299px;
    height: 441px;
}

.imgdiv{
    width: 837px;
    height: 441px;
    margin-top: 10px;
    
}
.meeetingtitle{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 30px;
    display: none;
}


@media (max-width: 991px){
    .halldiv {
        display: none;
        
    }
    .imgdiv{
        width: 100%!important;
        height: 100%!important;
        margin-top: 5px;
        
        
    }
    .meeetingtitle{
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 30px;
        display: block;
    }
    .meetingcols2{
        margin-left: 0px;
    }

    
    .meetingcols .largescrn{
        font-family: Georgia, 'Times New Roman', Times, serif;
        margin-top: 20px;
        
        
    }
    
    
}


@media (max-width: 480px){
    .halldiv {
        display: none;
        
    }
    .imgdiv{
        width: 100%;
        height: fit-content;
        margin-left: 0px;
    }
    .meeetingtitle{
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 30px;
        display: block;
    }
    .meetingcols2{
        margin-left: 0px;
    }

    .meetingcols .largescrn{
        font-family: Georgia, 'Times New Roman', Times, serif;
        margin-top: 20px;
        
    }
    
    
        
 
    
}

.meetingcols{
    border-style:solid;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-right: 20px;
    margin-top: 20px;
    width: 350px;

}

.meetingcols2{
    margin-left: 25px;
}

.evtag{
    font-size: 20px;
}

.aboutfmt2{
    width: 479px;
    height: 578px;
    float: right;
}

.formttxt4{
    color: rgb(83, 82, 82);
    font-size: 15px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height:35px;
    margin-top: 70px;
    
}

.formttxt5{
    color: rgb(83, 82, 82);
    font-size: 15px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-bottom: 40px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height:35px;
    margin-top: 30px;
    
}

.aboutfmt{
    width: 620px;
    height: 678px;
    float: right;
    object-fit: fill;
    margin-right: -110px;
    margin-top: 30px;
}





